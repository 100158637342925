.navbar {
  /* position: fixed; */
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: linear-gradient(
    270deg,
    rgba(17, 138, 138, 0.5) 0%,
    rgba(17, 138, 138, 0) 297.6%
  );
}
.nav_base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.5rem;
  margin: 0 auto;
  max-width: 1500px;
}
/* .navbar.colorChange {
  background-color: #118a8a;

     
} */
.back_nav {
  background-color: #118a8a;
}

.logo {
  /* width: 160px; */
  width: 200px;
}
.hamburger {
  display: none;
}

.main_nav {
  display: contents;
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
.nav-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-item {
  margin: 0 20px;
  white-space: nowrap;
}

.nav-link {
  font-size: 18px;
  font-weight: 500;
  color: #475569;
}

.nav-logo {
  padding: 5px;
}

.flexTaktest {
  display: flex;
  flex-direction: row;
  /* width: 400px; */
  width: 600px;
  justify-content: space-between;
  margin-right: 50px;
  align-items: center;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: black;
  padding: 5px;
  background-color: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
  font-family: inherit;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: 13px;
}

.scope {
  width: 30px;
  margin: 7px;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

.arrow {
  /* border: 2px solid white; */
  border: 2px solid #032C6B;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 5px;
  color: #032C6B !important;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.logins {
  border-radius: 30px;
  /* background: white; */
  background: transparent;
  /* display: flex; */
}
.navsgn {
  border: none;
  background-color: #fff;
  color: #000;
  padding: 10px 3px;
  overflow: hidden;
  border-radius: 15px;
  font-size: 16px;
  width: 74px;
}

.nav-signup {
  background: linear-gradient(
    97.14deg,
    #11888a -3.11%,
    #2a5e95 100%
  ) !important;
  color: #fff;
}

.contact {
  width: 30px;
}
.mobile_view {
  display: none;
}
.mobile_login {
  display: none;
}
.mob_login {
  display: none;
}
.mb_nv_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn_nv_tech {
  color: white;
  padding: 13px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 24px;
  /* background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%); */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: linear-gradient(
    97.14deg,
    #11888a -3.11%,
    #2a5e95 100%
  ) !important;
}

.flx_width {
  width: 80%;
}
.search_boxes {
  margin: 0 10px;
}
.rev_flx {
  justify-content: end;
  align-items: baseline;
}
.btn_ng_gn {
  display: flex;
  background-color: #032c6b;
  align-items: center;
  padding: 9px 20px;
  width: auto;
  margin-top: 5px;
}
.btn_ng_grey {
  display: flex;
  background-color: #808080e6;
  align-items: center;
  padding: 9px 10px;
  border-radius: 10px;
  border: 0px;
}


.topnumber-section{
  background-color: #D4FFF2;
  color: #032C6B !important;
  font-size: 20px;
  font-weight: 500;
  /* display: inline-block; */
  white-space: nowrap;
  display: flex;
  gap: 20px;
  padding: 5px 0 ;

  & .changelinksforancor{
    text-decoration: none;
    color: #032C6B !important;
    font-size: 20px;
    font-weight: 500;
  }
}

@media screen and (max-width:1120px) {
  .flx_width{
    width: 65%;
  }
  
}

@media screen and (max-width:1000px) {
  .flx_width{
    width: 65%;
  }
  
}

@media screen and (min-width: 993px) and (max-width: 1440px) {
  .logo {
    width: 140px;
  }
  .nav-item {
    margin: 0 10px;
    white-space: nowrap;
  }
  .nav-menu {
    padding: 0;
  }
  .nav-link {
    font-size: 17px;
  }
  .nav_base {
    padding: 1rem;
    max-width: 1500px;
  }
  .scope {
    width: 22px;
    margin: 5px;
  }
  .mb_nv_flex {
    display: flex;
    align-items: center;
  }
  /* .flx_width {
    width: 60%;
  } */
}
@media screen and (max-width: 992px) {
  .hamb {
    width: 60px;
    padding-right: 10px;
  }
  .logo {
    width: 150px;
  }
  .hm-bg {
    display: flex;
    align-items: center;
  }
  .navbar {
    padding: 15px 1px;
    top: 0px;
    width: 100%;
    background: linear-gradient(
      267deg,
      rgb(159 212 212) 0%,
      rgb(127 199 198) 297.6%
    );
    z-index: 2;
  }
  .nav_base {
    padding: 0 5px;
  }
  .main_nav {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    /* width: 100%; */
    /* max-height: 20px; */
  }
  .mobile_view {
    display: block;
  }
  .hamburger {
    display: inline-block;
    margin-right: 10px;
  }
  .nav-menu {
    display: none;
  }
  .nav-item {
    margin: 10px;
  }
  .nvs {
    display: block;
    display: flex;
    flex-direction: column;
  }
  /* .logins, .login {
    display: none;
  } */
  .contactcall {
    /* display: none; */
  }
  .drops-menus {
    display: flex;
    align-items: center;
  }
  .dropdown {
    padding: 19px 0;
    margin: 0 0;
    display: none;
  }
  .dropdown .dropbtn {
    border-radius: 8px;
  }

  .search_boxes {
    width: 100%;
    display: none;
  }
  .scope {
    width: 26px;
    margin: 3px;
  }
  .mob_login {
    display: block;
  }
  .login {
    display: none;
  }
  .nvb_side_bar {
    flex-direction: column;
    top: 50px;
    width: 200px;
    background-color: #ffffff;
    padding: 20px 10px;
  }
}

@media screen and (max-width: 768px) {
  .newDrawer {
    /* background-image: none; */
  }
  .hamb {
    width: 30px;
    padding-right: 4px;
  }
  .logo {
    width: 90%;
    height: auto;
  }
  .scope {
    width: 20px;
    margin: 2px;
  }
  .dropdown .dropbtn {
    border-radius: 8px;
    font-size: 13px;
  }
  .sm_first {
    font-size: 10px;
  }
  .sm_second {
    font-size: 10px;
  }
  .login {
    display: none;
  }
  .mob_login {
    display: block;
  }
  .search_boxes {
    display: none;
  }

  .flexTaktest {
    flex-direction: column;
  }

  .btn_nv_tech {
    background-color: #032c6b !important;
  }

  .topnumber-section{
    background-color: #D4FFF2;
    color: #032C6B !important;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  
    & .changelinksforancor{
      text-decoration: none;
      color: #032C6B !important;
      font-size: 20px;
      font-weight: 500;
    }
  }
}


/* @media screen and (max-width:992px){
  .nvb_side_bar{
    display: none;
  }
} */
