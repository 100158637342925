
.Explorebtnsection {
    padding: 50px 0;
    position: relative;
  }
  .ExploreBackground {
    background-color: #82a0d8;
    width: 100%;
    height: 200px;
    & img {
      width: 100%;
    }
  }
  
  .exploreimgTextbtn {
    display: flex;
    position: absolute;
    width: 60%;
    top: 45%;
    left: 20%;
    justify-content: space-around;
    align-items: center;
  
    & h3 {
      font-size: 34px;
      font-weight: 600;
      color: #ffffff;
    }
  
    & .explorbtn {
      background-color: #11898a;
      color: #ffffff;
      box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25);
      padding: 10px 30px;
      font-size: 18px;
      font-weight: 600;
    }

    & .explorbtn:hover{
      background-color: #11898a;
    }
  }
  .exploremobile {
    display: none;
  }
  
  .iconboxone {
    top: 45%;
    right: 12%;
  }
  .iconboxtwo {
    left: 0;
    bottom: 15%;
  }
  .postions {
    position: absolute;
  }

  .sectionHeading {
    font-size: 32px;
    font-weight: 600;
    color: #032c6b;
    text-align: center;
  }
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .ExploreBackground {
      height: 200px;
    }


    .sectionHeading {
      font-size: 24px;
      font-weight: 600;
    }
  .exploreimgTextbtn {
    width: 95%;
    top: 45%;
    left: 5px;
    justify-content: space-evenly;

    & h3 {
      font-size: 16px;
      font-weight: 600;
    }
    & div {
      width: 40px;

      & img {
        width: 100%;
      }
    }

    & .explorepc {
      display: none;
    }
  }
  .exploremobile {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 25%;
    left: 20%;
    /* margin: 40px 0 0; */

    & .explorbtn {
      background-color: #11898a ;
      color: #ffffff;
      box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25);
      padding: 10px 30px;
      font-size: 16px;
      font-weight: 700;
     
    }
  }

  .iconboxone {
    top: 60%;
    right: 20%;
    width: 45px;
  }
  .iconboxtwo {
    left: -115%;
    top: 52%;
  }
  }