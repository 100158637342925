.CardBox {
  background-color: #f9f9f9;
  width: 18%;
  /* text-align: center; */
  box-sizing: border-box;
  padding: 30px;
  position: relative;
  transition: all 0.5s ease;
  border-radius: 10px;
}

.CardBox:hover {
  transform: translate3D(0, -1px, 0) scale(1.03);
  transition: all 0.4s ease;
  box-shadow: 13px 13px 20px 0 rgba(94, 143,180,0.25);
}
.cardimgcontainer {
  width: 80%;
  margin: -90px auto 0;
  text-align: center;

  & img {
    /* width: 100%; */
    margin: 0  auto;
   
  }
}
.cardPara {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
}

.cardNumber {
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 50px;
  top: -15px;
  left: -15px;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(62, 103, 170, 1) 0%,
    rgba(122, 178, 189, 1) 100%
  );
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .CardBox {
    background-color: #f9f9f9;
    width: 240px;
    margin: 40px 15px;
  }
}
