.mainConatiner{

    & li{
        display: flex;
        justify-content: center;
        gap: 80px;
        flex-direction: row;
        margin-bottom: 40px;
        align-items: center;
        border-radius: 16px;
        padding: 20px 0;
    }

    & li:nth-child(odd){
        flex-direction: row-reverse;
    }
}

.ImageContainer{
    /* text-align: center; */
    width: 35%;
      
    & img{
        width: 100%;
    }
}

.TextContainer{
    width: 40%;
}

.textHeading{
    font-size: 30px;
    font-weight: 600;
}

.descriptionText{
    font-size: 25px;
    font-weight: 400;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {

    .mainConatiner{
        padding: 0;
        & li{
            display: block;
        }
    }

    .ImageContainer{
        width: 100%;
        & img{
            width: 100%;
        }
    }
    
    .TextContainer{
        width: 100%;
    }
}